
import { defineComponent } from '@nuxtjs/composition-api'
import { setupTranslationPrefixer } from '~/assets/util'

export default defineComponent({
  props: {
    animalIds: { type: Array, required: false, default: () => [] },
    description: { type: String, required: false, default: undefined },
    showDescription: { type: Boolean, required: false, default: true },
    showTitle: { type: Boolean, required: false, default: true },
    title: { type: String, required: false, default: undefined },
    translationGroup: { type: String, required: false, default: 'default' },
    url: { type: String, required: false, default: undefined },
    urlLabel: { type: String, required: false, default: undefined },
  },
  setup(props) {
    return { ...setupTranslationPrefixer('HighlightedAnimals', props.translationGroup) }
  },
})
