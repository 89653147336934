import { render, staticRenderFns } from "./HighlightedAnimals.vue?vue&type=template&id=16a872d7"
import script from "./HighlightedAnimals.vue?vue&type=script&lang=js"
export * from "./HighlightedAnimals.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomIconArrowRight: require('/home/userapp/components/Atom/Icon/IconArrowRight.vue').default,AtomButton: require('/home/userapp/components/Atom/Button.vue').default,MoleculeCarouselSlideAnimal: require('/home/userapp/components/Molecule/Carousel/CarouselSlideAnimal.vue').default,OrganismLoaderFromAnimalId: require('/home/userapp/components/Organism/Loader/LoaderFromAnimalId.vue').default,MoleculeCarousel: require('/home/userapp/components/Molecule/Carousel/Carousel.vue').default})
